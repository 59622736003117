<template>
  <v-form @submit.prevent="submitResponse">
    <text-block-ui-component :text="task.content.config.question" :task-state="taskState" />
    <single-calculation-ui-component
      :answer.sync="inputs.answer"
      :allow-editing="allowEditing"
      :prepend-text="task.content.config.prependText"
      :append-text="task.content.config.appendText"
    />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import SingleCalculationUiComponent from '@/tasks/components/task-ui-components/SingleCalculationUiComponent.vue';
import TextBlockUiComponent from '@/tasks/components/task-ui-components/TextBlockUiComponent.vue';

export default {
  name: 'SinglePartCalculation',
  components: {TextBlockUiComponent, SingleCalculationUiComponent},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        answer: '',
      },
    };
  },
};
</script>
